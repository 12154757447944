import './src/styles/global.less';

import React from 'react';
import CookieBot from 'react-cookiebot';

export const wrapRootElement = ({ element }) => (
  <>
    <CookieBot domainGroupId={process.env.GATSBY_COOKIEBOT} />
    {element}
  </>
);

export default null;
